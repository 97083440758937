import { Heading, InterfaceText, TextSize } from 'components/atoms/typography';
import React, { useEffect, useRef, useState } from 'react';

import { AppEventName } from 'lib/events/contracts';
import Carousel from 'components/molecules/carousel/Carousel';
import { Container } from 'components/atoms/layout/Container';
import type { IndustrySecretCartData } from './types';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import SecretDialog from './SecretDialog';
import SlateContent from 'components/molecules/cms/SlateContent';
import TapIcon from '@carbon/icons-react/es/touch--1/24';
import TrackSectionView from 'lib/events/TrackSectionView';
import { makeClassNames } from 'lib/util';

const CARD_FACE_CLASSNAMES =
  'backface-invisible absolute h-full w-full transition duration-1000 rounded-sm';

const opacityTransitionClassNames = 'transform duration-2000';

type IndustrySecretsProps = Readonly<{
  animate: boolean;
  cards: IndustrySecretCartData[];
}>;

const IndustrySecrets: React.FC<IndustrySecretsProps> = ({
  animate,
  cards
}) => {
  const cardRefs = useRef<HTMLDivElement[]>([]);
  const [secretCardFlipped, setCardFlipped] = useState(-1);
  const [secretRevealedIndex, setSecretRevealedIndex] = useState(-1);
  const secretsInView = useRef<Set<number>>(new Set());
  const [secretInFocus, setSecretInFocus] = useState({
    dirty: false,
    index: -1
  });
  // Track dialog data separate from open state to avoid content becoming null
  // before close animation is done
  const [dialogData, setDialogData] = useState<IndustrySecretCartData | null>(
    null
  );

  /**
   * Open Dialog after card is flipped
   */
  useEffect(() => {
    /**
     * Wait for card to nearly finish flipping and then show Dialog
     */
    setDialogData(cards[secretCardFlipped]);
    setTimeout(() => {
      setSecretRevealedIndex(secretCardFlipped);
    }, 750);
  }, [secretCardFlipped]);

  useEffect(() => {
    if (secretInFocus.dirty) {
      let indexInFocus = secretInFocus.index;
      const { current: secretRefs } = cardRefs;
      const { current } = secretsInView;
      const total = current.size;
      const lastIndex = secretRefs.length - 1;
      const values = Array.from(current.values()).sort();

      if (total == 2) {
        if (current.has(0)) {
          indexInFocus = 0;
        } else if (current.has(lastIndex)) {
          indexInFocus = lastIndex;
        }
      } else if (total === 3) {
        indexInFocus = values[1];
      }

      setSecretInFocus({
        dirty: false,
        index: indexInFocus
      });
    }
  }, [secretInFocus.dirty]);

  return (
    <>
      <Carousel
        animate={animate}
        cardClassName="h-[320px] w-[250px] lg:h-[400px] lg:w-[320px]"
        onCardClicked={index => {
          if (secretCardFlipped === -1) {
            setCardFlipped(index);
          } else {
            setCardFlipped(-1);
          }
        }}
        cards={cards.map((card, index) => {
          const { title, image, secondaryImage } = card;
          return (
            <React.Fragment key={title}>
              {/*
               * Card front face
               */}
              <div
                className={makeClassNames(
                  CARD_FACE_CLASSNAMES,
                  secretCardFlipped === index ? 'rotate-y-180' : 'rotate-y-0'
                )}
              >
                <div className="absolute top-0 h-full w-full">
                  {!!image && (
                    <ResponsiveImage data={image} className="rounded-sm" />
                  )}
                </div>
                <div className="absolute -left-2 bottom-rhythm2 z-10 w-4/5 rounded-sm bg-red-900 bg-opacity-80 p-rhythm-3 text-right">
                  <Heading
                    withoutSpacing
                    level={3}
                    size={TextSize.BodyCopy}
                    color="text-pearl-300"
                  >
                    {title}
                  </Heading>
                </div>
                <div
                  aria-hidden
                  className="absolute inset-0 z-10 overflow-hidden"
                >
                  <div className="absolute -right-[2.6rem] top-0 bg-red-700 p-3 rotate-45">
                    <div className="w-24 text-center">
                      {['Tap to', 'uncover'].map(line => (
                        <InterfaceText
                          color="text-pearl-300"
                          size={TextSize.LongPrimer}
                          className="block leading-none"
                          key={line}
                        >
                          {line}
                        </InterfaceText>
                      ))}
                    </div>
                  </div>
                </div>
                <SlateContent content={card.textSlate} className="sr-only" />
              </div>

              {/*
               * Card back face
               */}
              <div
                className={makeClassNames(
                  CARD_FACE_CLASSNAMES,
                  'h-full w-full',
                  secretCardFlipped === index ? 'rotate-y-0' : 'rotate-y-180'
                )}
              >
                {!!secondaryImage && (
                  <ResponsiveImage
                    data={secondaryImage}
                    className="rounded-sm"
                  />
                )}
              </div>
            </React.Fragment>
          );
        })}
      />

      <Container
        className={makeClassNames(
          'mt-4',
          opacityTransitionClassNames,
          animate ? 'opacity-100' : 'opacity-0'
        )}
      >
        <div className="flex items-center justify-center space-x-1">
          <TapIcon className="fill-primary" />
          <InterfaceText size={TextSize.LongPrimer} italic>
            Discover what's behind
          </InterfaceText>
        </div>
      </Container>
      <TrackSectionView
        eventFactory={() => ({ name: AppEventName.SawBeautyIndustrySecrets })}
      />

      {/*
       * Dialog for cards
       */}
      <SecretDialog
        isOpen={secretRevealedIndex > -1}
        data={dialogData}
        onClose={() => setSecretRevealedIndex(-1)}
        onCloseTransitionEnd={() => {
          setCardFlipped(-1);
          setDialogData(null);
        }}
        prevSecret={cards[secretRevealedIndex - 1]?.title}
        onNavigationPrev={() => {
          // TODO: with svelte allow to navigate from outside
          // scrollPrev();
          const prevIndex = secretRevealedIndex - 1;
          setSecretRevealedIndex(prevIndex);
          setDialogData(cards[prevIndex]);
          setCardFlipped(prevIndex);
        }}
        nextSecret={cards[secretRevealedIndex + 1]?.title}
        onNavigationNext={() => {
          // TODO: with svelte allow to navigate from outside
          // scrollNext();
          const nextIndex = secretRevealedIndex + 1;
          setSecretRevealedIndex(nextIndex);
          setDialogData(cards[nextIndex]);
          setCardFlipped(nextIndex);
        }}
      />
    </>
  );
};

export default IndustrySecrets;

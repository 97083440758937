// Generated with https://akashraj9828.github.io/svg-text-animation-generator/

import './fixWord.css';

import React, { useEffect, useRef } from 'react';

type FixWordProps = Readonly<{
  repeat?: boolean;
  animate: boolean;
}>;

type AnimationProps = {
  el: SVGSVGElement;
  delay: number;
  duration: number;
  strokeWidth: number;
  timingFunction: string;
  strokeColor: string;
  repeat: boolean;
};

function setTextAnimation({
  delay,
  duration,
  el,
  strokeWidth,
  timingFunction,
  strokeColor,
  repeat
}: AnimationProps) {
  const paths = el.querySelectorAll('path');
  const mode = repeat ? 'infinite' : 'forwards';
  for (let i = 0; i < paths.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path: any = paths[i];
    const length = path.getTotalLength();
    path.style['stroke-dashoffset'] = `${length}px`;
    path.style['stroke-dasharray'] = `${length}px`;
    path.style['stroke-width'] = `${strokeWidth}px`;
    path.style['stroke'] = `${strokeColor}`;
    path.style[
      'animation'
    ] = `${duration}s svg-text-anim ${mode} ${timingFunction}`;
    path.style['animation-delay'] = `${i * delay}s`;
  }
}

const FixWord: React.FC<FixWordProps> = ({ repeat = false, animate }) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (animate && ref.current) {
      setTextAnimation({
        delay: 0,
        duration: 2,
        el: ref.current,
        strokeWidth: 4,
        timingFunction: 'ease-in-out',
        strokeColor: '#144453',
        repeat: !!repeat
      });
    }
  }, [animate]);

  return (
    <svg
      ref={ref}
      width="241.08"
      height="160"
      viewBox="0 0 241.08 154"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="svgGroup"
        strokeLinecap="round"
        fillRule="evenodd"
        fontSize="9pt"
        // stroke="#00000"
        strokeWidth="0.25mm"
        fill="none"
        // styles before animation begins
        // style={{ stroke: '#00000', strokeWidth: '0.25mm', fill: 'none' }}
      >
        <path
          d="M 46.535 151.7 L 13.735 151.7 L 13.735 77.695 L 0 77.695 L 0 52.89 L 13.735 52.89 L 13.735 44.075 A 66.687 66.687 0 0 1 14.618 32.943 Q 15.7 26.554 18.107 21.227 A 41.341 41.341 0 0 1 18.348 20.705 A 37.371 37.371 0 0 1 25.415 10.349 A 34.014 34.014 0 0 1 31.468 5.433 A 34.619 34.619 0 0 1 46.784 0.224 A 43.575 43.575 0 0 1 51.25 0 Q 58.22 0 65.19 1.64 Q 72.16 3.28 79.335 6.355 L 73.39 30.955 Q 70.363 29.542 66.343 28.626 A 48.449 48.449 0 0 0 66.215 28.598 A 40.009 40.009 0 0 0 62.269 27.906 A 30.164 30.164 0 0 0 58.63 27.675 A 14.87 14.87 0 0 0 55.038 28.083 A 9.832 9.832 0 0 0 49.61 31.468 A 11.99 11.99 0 0 0 47.619 35.175 Q 47.026 36.907 46.758 39.038 A 30.515 30.515 0 0 0 46.535 42.845 L 46.535 52.89 L 71.545 52.89 L 71.545 77.695 L 46.535 77.695 L 46.535 151.7 Z"
          id="0"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M 118.49 151.7 L 85.69 151.7 L 85.69 44.075 L 118.49 44.075 L 118.49 151.7 Z M 118.49 31.775 L 85.69 31.775 L 85.69 2.05 L 118.49 2.05 L 118.49 31.775 Z"
          id="1"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M 130.38 44.075 L 164.205 44.075 L 183.27 74.415 L 185.32 78.31 L 187.37 74.415 L 206.435 44.075 L 240.26 44.075 L 204.18 97.58 L 241.08 151.7 L 207.255 151.7 L 187.165 121.565 L 185.32 117.67 L 183.475 121.565 L 163.385 151.7 L 129.56 151.7 L 166.46 98.195 L 130.38 44.075 Z"
          id="2"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
};

export default FixWord;

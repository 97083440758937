import React, { Fragment } from 'react';

import CloseIcon from '@carbon/icons-react/es/close/24';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { makeClassNames } from 'lib/util';

export const enum ModalDialogPosition {
  Bottom,
  Center
}

type ModalDialogProps = PropsWithChildren & {
  onClose?: (value: boolean) => void;
  onCloseTransitionEnd?: () => void;
  isOpen: boolean;
  mobilePosition?: ModalDialogPosition;
  /**
   * Configure z-index when the Dialog is not meant
   * to be on top of everything.
   *
   * The default is the highest to avoid bugs with headless-ui
   * For example:
   *   - QuickView dialog is open on mobile
   *   - User taps on menu icon from floating bar
   *   - QuickVIew closes and mobile menu dialog opens
   *   - User closes menu
   *   - the document has a style of overflow hidden and scrolling is not possible
   * @default "z-60"
   */
  zIndex?: string;
};

const ModalDialog = React.forwardRef<HTMLDivElement, ModalDialogProps>(
  (
    {
      children,
      isOpen,
      onClose = () => {},
      onCloseTransitionEnd = () => {},
      mobilePosition = ModalDialogPosition.Center,
      zIndex = 'z-60'
    },
    ref
  ) => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={makeClassNames('relative', zIndex)}
          onClose={onClose}
        >
          {/* Background */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-pearl-900 bg-opacity-50" />
          </Transition.Child>

          <div
            ref={ref}
            className={makeClassNames(
              'fixed overflow-y-auto',
              mobilePosition === ModalDialogPosition.Center
                ? 'inset-0'
                : 'inset-x-0 bottom-0 lg:inset-0'
            )}
          >
            <div className="flex min-h-full items-center justify-center pt-rhythm1 pb-rhythm4 lg:pb-rhythm1">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                afterLeave={onCloseTransitionEnd}
              >
                <Dialog.Panel
                  className={makeClassNames(
                    'relative w-full overflow-hidden rounded-2xl bg-pearl-200 p-rhythm2 shadow-xl transition-all transform',
                    'mx-4 pb-rhythm3',
                    'md:mx-0 md:max-w-4/5',
                    'lg:p-rhythm3 lg:pb-rhythm2',
                    'xl:max-w-3xl'
                  )}
                >
                  <button
                    className="absolute right-4 top-4 z-10 flex h-[40px] w-[40px] items-center justify-center rounded-full border border-primary bg-pearl-500 fill-primary transition-colors hover:fill-primary-400"
                    onClick={() => onClose(true)}
                    type="button"
                  >
                    <CloseIcon className="fill-inherit" />
                  </button>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default ModalDialog;

import { BodyText, Heading } from 'components/atoms/typography';

import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
import React from 'react';
import StaticImage from '@svelte/reactify/StaticImage';

export type FounderData = {
  bio: string;
  name: string;
  avatar: CdnImageDataFragment;
};

type FoundersInfoProps = Readonly<{
  data: FounderData[];
}>;

const FoundersInfo: React.FC<FoundersInfoProps> = ({ data }) => {
  return (
    <>
      {data.map(founder => {
        const { name, avatar, bio } = founder;
        return (
          <div key={name}>
            <div className="flex flex-col items-center justify-center py-rhythm1">
              <StaticImage
                className="rounded-full"
                display="block md:hidden"
                height={180}
                width={180}
                data={avatar}
                crop="thumb"
                gravity="face"
                zoom={0.8}
              />
              <StaticImage
                className="rounded-full"
                display="hidden md:block"
                height={220}
                width={220}
                data={avatar}
                crop="thumb"
                gravity="face"
                zoom={0.8}
              />

              <Heading level={3} bold>
                {name}
              </Heading>
            </div>
            <BodyText>{bio}</BodyText>
          </div>
        );
      })}
    </>
  );
};

export default FoundersInfo;

import CertificationLogo, {
  LogoData
} from 'components/molecules/banners/certifications/CertificationLogo';
import { InterfaceText, TextSize } from 'components/atoms/typography';
import Media, { smallScreenHeightFiller } from './Media';
import { OurStoryPageQuery, StrapiMark } from 'gatsby/graphqlTypes';

import { AppEventName } from 'lib/events/contracts';
import { Container } from 'components/atoms/layout/Container';
import React from 'react';
import SlateContent from 'components/molecules/cms/SlateContent';
import TrackSectionView from 'lib/events/TrackSectionView';
import { makeClassNames } from 'lib/util';

export type PillarData = NonNullable<
  NonNullable<OurStoryPageQuery['strapiOurStory']>['solutionBlocks'][0]
>;

type CompanyPillarProps = PropsWithClassName &
  Readonly<{
    item: PillarData;
    index: number;
    marks?: Array<StrapiMark>;
    isLast?: boolean;
  }>;

const titleBannerHeight = 'h-[86px]';
const headingBgClassName = 'bg-primary-200 bg-opacity-60';

const wideMarkRegex = /Britain|Planet|PETA/i;

const CompanyPillar: React.FC<CompanyPillarProps> = ({
  item,
  index,
  marks = [],
  className,
  isLast
}) => {
  if (!item) {
    return null;
  }

  const { image, title, textSlate } = item;
  const isOdd = (index + 1) % 2;
  const media = (
    <Media
      headingBgClassName={headingBgClassName}
      media={item}
      position="right"
      isLast={isLast}
    />
  );

  return (
    <div
      className={makeClassNames('relative xl:grid xl:grid-cols-2', className)}
    >
      {isOdd ? null : media}
      {!!isOdd && !!image && <div className={smallScreenHeightFiller} />}

      <div className="relative z-20 w-full pb-rhythm2 xl:min-h-screen">
        <div
          className={makeClassNames(
            'w-full backdrop-blur-sm',
            headingBgClassName,
            titleBannerHeight
          )}
        >
          <Container>
            <InterfaceText bold size={TextSize.Trafalgar} color="text-primary">
              {index + 1}.
            </InterfaceText>
          </Container>
        </div>

        <div className="relative">
          <div className="absolute inset-0 bg-pearl-500 bg-opacity-50 xl:hidden" />
          <Container className="relative z-10 pb-rhythm2 xl:pb-0">
            <div>
              <InterfaceText size={TextSize.DoublePica} color="text-primary">
                {title}
              </InterfaceText>
              {marks.length ? (
                <div className="mt-rhythm2 flex h-rhythm5 w-full space-x-rhythm1">
                  {marks.map(mark => {
                    let width = 80;
                    if (wideMarkRegex.test(mark.name)) {
                      width = 120;
                    }
                    return (
                      <CertificationLogo
                        key={mark.name}
                        data={mark as LogoData}
                        width={width}
                      />
                    );
                  })}
                </div>
              ) : null}

              <SlateContent content={textSlate} className="pt-rhythm2" />
            </div>

            <TrackSectionView
              eventFactory={() => ({
                name: AppEventName.SawCompanyPillar,
                payload: { name: title }
              })}
            />
          </Container>
        </div>
      </div>

      {isOdd ? media : null}
    </div>
  );
};

export default CompanyPillar;

import { MovingImage } from '@svelte/reactify/MovingImage';
import { OurStoryPageQuery } from 'gatsby/graphqlTypes';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { makeClassNames } from 'lib/util';

type Item = NonNullable<
  NonNullable<OurStoryPageQuery['strapiOurStory']>['solutionBlocks'][0]
>;

const mediaHeight = 'max-h-[18rem] md:max-h-[32rem]';
const mediaPaddingLgScreenClassNames = 'min-h-rhythm7 flex-1 hidden xl:block';
export const smallScreenHeightFiller =
  'h-[calc(18rem-86px-60px)] md:h-[calc(32rem-86px-60px)] xl:hidden';

type PillarMediaProps = Readonly<{
  media: Pick<Item, 'movingImage' | 'image'>;
  position?: 'left' | 'right';
  isLast?: boolean;
  headingBgClassName: string;
}>;

const PillarMedia: React.FC<PillarMediaProps> = ({
  media,
  position,
  isLast,
  headingBgClassName
}) => {
  const { movingImage, image } = media;

  if (movingImage) {
    return (
      <div className="flex-col xl:flex xl:h-full">
        <MovingImage
          className="z-10 w-full xl:sticky xl:top-0"
          video={movingImage}
        />
        {!isLast && <div className={mediaPaddingLgScreenClassNames} />}
      </div>
    );
  }

  if (image) {
    return (
      <div
        className={makeClassNames(
          'flex-col xl:sticky xl:flex xl:h-full',
          headingBgClassName
        )}
      >
        <div
          className={makeClassNames(
            'absolute top-0 z-10 w-rhythm10 md:w-rhythm12 xl:sticky xl:h-screen xl:w-full xl:flex-col',
            mediaHeight,
            position === 'left' ? 'left-0' : 'right-0'
          )}
        >
          <ResponsiveImage height="h-rhythm10 md:h-rhythm12" data={image} />
        </div>
        <div className={mediaPaddingLgScreenClassNames} />
      </div>
    );
  }

  return null;
};

export default PillarMedia;

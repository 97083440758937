import React from 'react';
import { makeClassNames } from 'lib/util';

type SecretsSlicedWordProps = PropsWithClassName & {
  withAnimation?: boolean;
  animate: boolean;
};

const commonClassNames = () => ['transform', 'duration-2000', 'ease-in-out'];

const SecretsSlicedWord: React.FC<SecretsSlicedWordProps> = ({
  className,
  animate,
  withAnimation
}) => {
  const topGroupClassNames = commonClassNames();
  const bottomGroupClassNames = commonClassNames();

  if (withAnimation) {
    if (!animate) {
      topGroupClassNames.push('-translate-x-12 opacity-0');
      bottomGroupClassNames.push('translate-y-6 translate-x-6 opacity-0');
    } else {
      topGroupClassNames.push('translate-x-0 opacity-full');
      bottomGroupClassNames.push(
        'translate-y-[-3.4219px] translate-x-[.66954px] opacity-full'
      );
    }
  }

  return (
    <svg
      className={className}
      width="97.52mm"
      height="20.538mm"
      version="1.1"
      viewBox="0 0 97.52 20.538"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>secrets</title>
      <g
        fill="#144453"
        strokeWidth="3.7451"
        className={makeClassNames(topGroupClassNames)}
      >
        <path d="m26.116 18.289c0.72941-0.75 1.2536-1.7117 1.5653-2.8928l-0.5395-0.08992c-0.43203 1.4881-1.1543 2.5257-2.1632 3.1197z" />
        <path d="m42.096 16.365c0.15165-0.39816 0.28169-0.82872 0.38551-1.2986l-0.5395-0.05995c-0.09912 0.50553-0.26004 0.98364-0.48214 1.4351z" />
        <path d="m10.187 4.8835c-0.19974 0.3795-0.3692 0.63946-0.50901 0.77928-0.13982 0.13982-0.30979 0.20981-0.50953 0.20981s-0.48918-0.079988-0.86868-0.23978c-1.0386-0.4594-2.0475-0.68936-3.0262-0.68936-1.5979 0-2.8762 0.46942-3.8349 1.4082-0.95874 0.91879-1.4382 2.1576-1.4382 3.7155s0.5494 2.6962 1.648 3.4153c0.91879 0.59921 2.0571 1.0786 3.4153 1.4382 1.2184 0.23968 2.2172 0.57912 2.9962 1.0185 0.49934 0.27963 0.74879 0.69906 0.74879 1.2583 0 1.2983-1.0183 1.9477-3.0556 1.9477-1.3183 0-2.4171-0.34943-3.2959-1.0485-0.87885-0.69908-1.5777-1.9179-2.097-3.6556h-0.35967v5.363h0.35967c0.41945-0.73903 0.84887-1.1085 1.2883-1.1085 0.13982 0 0.32927 0.07999 0.56896 0.23978 1.1585 0.57924 2.3573 0.86868 3.5956 0.86868 1.7377 0 3.106-0.42942 4.1047-1.2883 0.99869-0.87885 1.4976-2.1571 1.4976-3.8349 0-1.6978-0.54889-2.926-1.6474-3.685-0.53929-0.35953-1.1087-0.65897-1.7079-0.89865-0.59921-0.25966-1.578-0.55962-2.9363-0.89917-0.11984-0.019974-0.3093-0.069967-0.56896-0.14986l-0.5395-0.17983c-0.11984-0.059921-0.26982-0.12939-0.44958-0.20929-0.17976-0.099868-0.30923-0.18986-0.38912-0.26975-0.079895-0.079895-0.18988-0.19988-0.3297-0.35967-0.13982-0.17976-0.20981-0.41921-0.20981-0.71882 0-0.49934 0.22997-0.90877 0.68936-1.2283 0.4594-0.33955 1.1583-0.50953 2.097-0.50953 2.1372 0 3.7149 1.2983 4.7336 3.8949h0.35967v-4.5842z" />
        <path d="m84.944 10.942 7.7634-0.93483c-0.6076-0.23877-1.4975-0.50731-2.7053-0.80925-0.11984-0.01997-0.30982-0.06997-0.56947-0.14986l-0.5395-0.17983c-0.11984-0.05992-0.2693-0.12939-0.44907-0.20929-0.17976-0.09987-0.30974-0.18986-0.38964-0.26975-0.0799-0.0799-0.18988-0.19988-0.3297-0.35967-0.13982-0.17976-0.20981-0.41921-0.20981-0.71882 0-0.49934 0.22997-0.90877 0.68936-1.2283 0.4594-0.33955 1.1583-0.50953 2.097-0.50953 2.1372 0 3.7154 1.2983 4.7341 3.8949h0.35915v-4.5842h-0.32918c-0.19974 0.3795-0.36971 0.63946-0.50953 0.77928s-0.30979 0.20981-0.50953 0.20981-0.48918-0.079988-0.86868-0.23978c-1.0386-0.4594-2.0475-0.68936-3.0262-0.68936-1.5979 0-2.8762 0.46942-3.8349 1.4082-0.95874 0.91879-1.4382 2.1576-1.4382 3.7155 0 0.30832 0.0226 0.59941 0.06563 0.87488z" />
        <path d="m20.476 18.704c-0.65536-0.22326-1.1497-0.66426-1.4805-1.326-0.49934-0.99869-0.74931-2.4769-0.74931-4.4344v-1.1684h8.4491c0.17976-4.554-1.9973-6.8311-6.5314-6.8311-2.2371 0-4.0847 0.62939-5.5428 1.8877-1.4381 1.2583-2.157 3.076-2.157 5.4529 0 2.3569 0.63887 4.2046 1.9172 5.5428 0.57224 0.58104 1.2692 1.0336 2.0898 1.3586zm-0.5519-13.19c0.57924 0 0.95867 0.29944 1.1384 0.89865 0.19974 0.57924 0.29972 1.678 0.29972 3.2959v1.4681h-3.0861v-1.4681c0-1.538 0.12998-2.6168 0.38964-3.236 0.27963-0.63916 0.69906-0.9586 1.2583-0.9586z" />
        <path d="m34.178 17.054c-0.31398-0.87227-0.47232-2.1396-0.47232-3.8111v-1.558c0-2.2171 0.18946-3.8048 0.56896-4.7635 0.3795-0.95874 1.0089-1.4382 1.8877-1.4382 0.95874 0 1.4382 0.47941 1.4382 1.4382 0 0.31958-0.06999 0.67901-0.20981 1.0785h-0.98857c-0.09987 0.35953-0.14986 0.71896-0.14986 1.0785 0 0.81892 0.23945 1.4383 0.71882 1.8578 0.47937 0.41945 1.1088 0.6289 1.8877 0.6289 0.79895 0 1.4178-0.23945 1.8573-0.71882 0.4594-0.47937 0.68936-1.2382 0.68936-2.2769 0-1.0386-0.46942-1.8975-1.4082-2.5766-0.93877-0.69908-2.297-1.049-4.0747-1.049-2.5167 0-4.4838 0.64939-5.902 1.9477-1.3982 1.2983-2.0975 3.156-2.0975 5.5728 0 2.1971 0.52165 3.9143 1.5622 5.1547z" />
        <path d="m49.465 15.214v-4.2778c0-1.3183 0.27944-2.4571 0.83871-3.4158 0.55927-0.95874 1.2087-1.4376 1.9477-1.4376 0.91879 0 1.1282 0.50889 0.6289 1.5276h-1.0785c-0.31958 1.0586-0.26959 1.888 0.14986 2.4872 0.43942 0.59921 1.1088 0.89865 2.0076 0.89865 0.91879 0 1.5977-0.25996 2.0371-0.77928s0.65939-1.2582 0.65939-2.2169c0-0.97872-0.29996-1.7276-0.89917-2.2469-0.57924-0.53929-1.3381-0.80925-2.2769-0.80925-0.93877 0-1.7776 0.27996-2.5166 0.83923-0.73903 0.53929-1.2384 1.3181-1.4981 2.3368v-2.8763h-7.2207v0.5395h1.648v10.102z" />
        <path d="m62.869 13.6c-0.0066-0.21308-0.01085-0.43096-0.01085-0.65681v-1.1684h8.4491c0.17976-4.554-1.9973-6.8311-6.5314-6.8311-2.2371 0-4.0847 0.62939-5.5428 1.8877-1.4381 1.2583-2.1575 3.076-2.1575 5.4529 0 0.71064 0.05949 1.3741 0.1757 1.9921zm1.6671-8.0868c0.57924 0 0.95867 0.29944 1.1384 0.89865 0.19974 0.57924 0.29972 1.678 0.29972 3.2959v1.4681h-3.0861v-1.4681c0-1.538 0.12998-2.6168 0.38964-3.236 0.27963-0.63916 0.69906-0.9586 1.2583-0.9586z" />
        <path d="m79.456 0-5.5728 1.3782v3.8649h-1.648v0.5395h1.648v6.4911l5.5728-0.67128v-5.8198h3.8949v-0.5395h-3.8949z" />
      </g>
      <g
        transform="translate(.66954 -3.4219)"
        fill="#144453"
        strokeWidth="3.7451"
        className={makeClassNames(bottomGroupClassNames)}
      >
        <path d="m85.501 15.098c0.17438 1.1165 0.70118 1.9637 1.5823 2.5404 0.91879 0.59921 2.0576 1.0786 3.4158 1.4382 1.2184 0.23968 2.2167 0.57912 2.9957 1.0185 0.49934 0.27963 0.74931 0.69906 0.74931 1.2583 0 1.2983-1.0188 1.9477-3.0561 1.9477-1.3183 0-2.4166-0.34943-3.2954-1.0485-0.87885-0.69908-1.5782-1.9179-2.0975-3.6556h-0.35967v5.363h0.35967c0.41945-0.73903 0.84887-1.1085 1.2883-1.1085 0.13982 0 0.32979 0.07999 0.56948 0.23978 1.1585 0.57924 2.3568 0.86868 3.5951 0.86868 1.7377 0 3.106-0.42942 4.1047-1.2883 0.99869-0.87885 1.4981-2.1571 1.4981-3.8349 0-1.6978-0.5494-2.926-1.648-3.685-0.53929-0.35953-1.1087-0.65897-1.7079-0.89865-0.06637-0.02876-0.1548-0.06018-0.23048-0.08992z" />
        <path d="m25.687 22.299-1.1374 0.13694c-0.67025 0.39463-1.4657 0.59531-2.3906 0.59531-0.4178 0-0.79235-0.05841-1.126-0.17208l-4.0049 0.48214c1.0412 0.41228 2.2818 0.61857 3.7228 0.61857 1.9774 0 3.4556-0.39943 4.4344-1.1984 0.17842-0.14274 0.34312-0.29937 0.50178-0.4625z" />
        <path d="m41.667 20.376-0.63614 0.07648c-0.22544 0.45821-0.51395 0.88894-0.8661 1.2914-0.69908 0.79895-1.6379 1.1984-2.8164 1.1984s-1.9873-0.42942-2.4267-1.2883c-0.06798-0.13287-0.12909-0.28374-0.18655-0.44338l-4.6927 0.56482c0.09462 0.1128 0.19236 0.22326 0.29559 0.32815 1.2384 1.2384 3.016 1.8573 5.333 1.8573 1.8176 0 3.2453-0.38943 4.284-1.1684 0.74-0.55137 1.3093-1.3591 1.712-2.4164z" />
        <path d="m50.022 19.37-5.5728 0.67076v3.0809h-1.648v0.53898h9.3478v-0.53898h-2.127z" />
        <path d="m63.426 17.756-5.6177 0.67644c0.26922 1.4317 0.84912 2.6159 1.742 3.5507 1.2983 1.3183 3.2354 1.9771 5.812 1.9771 1.9774 0 3.4556-0.39943 4.4344-1.1984 0.99869-0.79895 1.6881-1.9172 2.0676-3.3554l-0.5395-0.08992c-0.71906 2.4767-2.2373 3.715-4.5542 3.715-1.2384 0-2.1072-0.49941-2.6066-1.4981-0.44173-0.88346-0.68697-2.1438-0.73794-3.7775z" />
        <path d="m80.013 15.759-5.5728 0.67128v2.9466c0 1.7377 0.43942 2.936 1.3183 3.5951 0.87885 0.65914 2.1471 0.98857 3.8049 0.98857s2.8461-0.39943 3.5652-1.1984c0.71906-0.81892 1.1585-1.9172 1.3183-3.2954l-0.50901-0.05995c-0.13982 1.0386-0.43977 1.8575-0.89917 2.4567-0.43942 0.59921-0.95883 0.89865-1.558 0.89865-0.59921 0-0.99864-0.21945-1.1984-0.65887-0.17976-0.43942-0.26923-1.1288-0.26923-2.0676z" />
      </g>
    </svg>
  );
};

export default SecretsSlicedWord;

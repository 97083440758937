<script context="module" lang="ts">
  function calculatePosterHash(hashBin?: number[]) {
    return (
      canUseDom() &&
      hashBin &&
      window.thumbHashToDataURL &&
      window.thumbHashToDataURL(hashBin)
    );
  }
</script>

<script lang="ts">
  import { ResponsiveVideoFragment } from 'gatsby/graphqlTypes';
  import { AppErrorName } from 'lib/events/errors';
  import HashRenderer from './HashRenderer.svelte';
  import { canUseDom, makeClassNames } from 'lib/util';
  import { appEventDispatcher } from 'lib/events/Provider';
  import { inview, type Options as SvelteInviewOptions } from 'svelte-inview';
  import { mediaAssetDataSaver } from 'lib/device/state';
  import { onMount } from 'svelte';

  export let className: string | undefined = undefined;
  export let video: ResponsiveVideoFragment;
  export let inViewOpts: SvelteInviewOptions = {};
  export let controls = false;
  export let autoplay = true;
  export let muted = true;

  export let isInView = false;
  export let element: HTMLVideoElement | undefined = undefined;

  // TODO: dispatcher from context
  // const dispatcher = getContext(SVELTE_CONTEXT_KEYS.appEventDispatcher);
  const hashBin = video.poster?.thumbhash as number[] | undefined;
  const isBrowser = typeof window !== 'undefined';
  const ssrId = isBrowser ? undefined : (Math.random() * 10e15).toString(16);
  let poster = calculatePosterHash(hashBin) || null;
  let posterLoaded = false;
  let hasEnteredViewOnce = false;
  $: resolution = $mediaAssetDataSaver ? 720 : 1080;

  onMount(() => {
    if (!poster && hashBin) {
      const updatePoster = () => {
        const hash = calculatePosterHash(hashBin);
        if (!posterLoaded && hash) {
          poster = hash;
        } else {
          setTimeout(updatePoster, 50);
        }
      };
      updatePoster();
    }
  });

  const handleError: svelte.JSX.EventHandler<
    Event,
    HTMLVideoElement
  > = event => {
    const mediaError = event.currentTarget.error;
    appEventDispatcher.dispatchError(AppErrorName.VideoError, {
      video,
      mediaError
    });
  };

  const handleErrorListener = handleError as svelte.JSX.EventHandler<
    Event,
    Element
  >;

  const handleInView = () => {
    if (!posterLoaded) {
      posterLoaded = true;
      const posterUrl = video.poster?.url;
      if (posterUrl) {
        fetch(new Request(posterUrl))
          .then(response => response.blob())
          .then(blob => {
            poster = URL.createObjectURL(blob);
          })
          .catch(() => {
            // TODO: emit image error
            posterLoaded = false;
          });
      }
    }
  };

  const fallbackSource = video.sources.find(
    source => source?.mimeType === 'video/mp4'
  );

  const videoEl = canUseDom() && document.createElement('video');
  // For this to work, the sources in Strapi
  // should always be sorted from best option to least optimal
  $: source = video.sources.find(
    source =>
      source &&
      videoEl &&
      videoEl.canPlayType(source.mimeType) === 'probably' &&
      source.resolution === resolution
  );
</script>

<!-- <noscript> are only rendered by Svelte in SSR -->
<noscript>
  <style type="text/css">
    .no-js {
      display: none;
    }
  </style>
  <video
    {muted}
    {controls}
    {autoplay}
    disablePictureInPicture
    playsInline
    loop
    disableRemotePlayback
    title={video.title}
    class={className}
    {poster}
    on:error={handleErrorListener}
    src={fallbackSource ? fallbackSource?.file?.url : undefined}
  />
</noscript>

<!-- <video> only allows changing the file using the src attribute, but not with <source> -->
<video
  use:inview={inViewOpts}
  bind:this={element}
  on:inview_change={event => {
    if (event.detail.inView && !hasEnteredViewOnce) {
      hasEnteredViewOnce = true;
    }
    isInView = event.detail.inView;
  }}
  on:inview_enter={handleInView}
  data-vid={ssrId}
  {muted}
  {controls}
  autoplay={isInView && autoplay}
  disablePictureInPicture
  playsInline
  loop
  disableRemotePlayback
  class={makeClassNames('no-js', className)}
  title={video.title}
  {poster}
  on:error={handleErrorListener}
  src={hasEnteredViewOnce
    ? source?.file?.url
      ? source.file.url
      : fallbackSource?.file?.url
    : undefined}
/>

{#if hashBin && ssrId}
  <HashRenderer {hashBin} {ssrId} />
{/if}

import { InterfaceText, TextSize } from 'components/atoms/typography';
import React, { useEffect, useRef } from 'react';

import { AppEventName } from 'lib/events/contracts';
import { Button } from 'components/atoms/button/Button';
import { Dialog } from '@headlessui/react';
import { IndustrySecretCartData } from './types';
import ModalDialog from 'components/molecules/dialog/Dialog';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import SlateContent from 'components/molecules/cms/SlateContent';
import TrackSectionView from 'lib/events/TrackSectionView';
import useAppEventDispatcher from 'lib/events/hooks';

type SecretDialogProps = Readonly<{
  data?: IndustrySecretCartData | null;
  isOpen: boolean;
  onClose: () => void;
  onCloseTransitionEnd: () => void;
  onNavigationPrev: () => void;
  prevSecret?: string;
  onNavigationNext: () => void;
  nextSecret?: string;
}>;

const SecretDialog: React.FC<SecretDialogProps> = ({
  data,
  onClose,
  onCloseTransitionEnd,
  onNavigationPrev,
  prevSecret,
  onNavigationNext,
  nextSecret,
  isOpen
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const content = data?.textSlate;
  const title = data?.title;
  const image = data?.secondaryImage;
  const prevData = useRef(data);
  const eventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    /**
     * Scroll to top
     * when user navigates within Dialog
     */
    const hasChanged = prevData.current !== data;
    const needsScroll = prevData.current && hasChanged;
    if (hasChanged) {
      prevData.current = data;
      if (needsScroll && dialogRef.current) {
        dialogRef.current.scrollTop = 0;
      }
    }
  }, [data]);

  return (
    <ModalDialog
      ref={dialogRef}
      isOpen={isOpen}
      onClose={onClose}
      onCloseTransitionEnd={onCloseTransitionEnd}
    >
      <div className="absolute inset-0 flex h-[16rem] flex-col justify-end overflow-hidden">
        <TrackSectionView
          eventFactory={() => ({
            name: AppEventName.ReadAboutIndustrySecret,
            payload: {
              secretName: title as string
            }
          })}
        />
        <div className="absolute h-full w-full">
          {!!image && <ResponsiveImage data={image} />}
        </div>
        <Dialog.Title
          as="h3"
          className="relative z-10 flex h-rhythm6 w-full items-end justify-center bg-gradient-to-b from-transparent to-pearl-200 pb-4"
        >
          <InterfaceText bold size={TextSize.Pica}>
            {title}
          </InterfaceText>
        </Dialog.Title>
      </div>

      <div className="relative z-10 mt-[16rem]">
        {content && <SlateContent content={content} className="mt-2" />}

        <div className="flex justify-between">
          <button
            type="button"
            className="flex max-w-20 flex-col items-start"
            onClick={() => {
              if (prevSecret) {
                onNavigationPrev();
                eventDispatcher.dispatch(AppEventName.ReadAboutIndustrySecret, {
                  secretName: prevSecret
                });
              }
            }}
          >
            {prevSecret && (
              <>
                <InterfaceText>{'<<'}</InterfaceText>
                <InterfaceText
                  size={TextSize.LongPrimer}
                  className="overflow-hidden text-ellipsis text-left leading-none"
                >
                  {prevSecret}
                </InterfaceText>
              </>
            )}
          </button>
          {nextSecret && (
            <button
              type="button"
              onClick={() => {
                onNavigationNext();
                eventDispatcher.dispatch(AppEventName.ReadAboutIndustrySecret, {
                  secretName: nextSecret as string
                });
              }}
              className="flex max-w-20 flex-col items-end text-right"
            >
              <InterfaceText>{'>>'}</InterfaceText>
              <InterfaceText
                size={TextSize.LongPrimer}
                className="overflow-hidden text-ellipsis leading-none"
              >
                {nextSecret}
              </InterfaceText>
            </button>
          )}
        </div>

        <div className="flex justify-center py-rhythm2">
          <Button
            kind="ghost"
            size="xs"
            className="w-1/2"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default SecretDialog;

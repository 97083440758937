<script lang="ts">
  import { onMount } from 'svelte';

  export let hashBin: number[];
  export let ssrId: string;

  $: mounted = false;

  onMount(() => (mounted = true));

</script>

{#if !mounted}
  <!-- NOTE: prettier may mess this up -->
  <!-- prettier-ignore -->
  {@html `<script>(() => {const e=document.querySelector('[data-vid="${ssrId}"]'),render=()=>{setTimeout((()=>{const t=window.thumbHashToDataURL&&window.thumbHashToDataURL(new Uint8Array([${hashBin}]));t?e.poster=t:render()}),50)};render();})()</script>`}
{/if}


import {
  CdnImageDataFragment,
  OurStoryPageQuery,
  StrapiMark,
  StrapiOurStory
} from 'gatsby/graphqlTypes';
import CompanyPillar, { PillarData } from 'components/organisms/company/pillar';
import FoundersInfo, {
  FounderData
} from 'components/organisms/company/FoundersInfo';

import { AppEventName } from 'lib/events/contracts';
import { AppPageProps } from 'gatsby/types';
import Balancer from 'react-wrap-balancer';
import { Container } from 'components/atoms/layout/Container';
import FixWord from 'components/atoms/animations/fix-word';
import { Heading } from 'components/atoms/typography';
import { IndustrySecretCartData } from 'components/organisms/company/industry-secrets/types';
import IndustrySecrets from 'components/organisms/company/industry-secrets';
import PageContentLayout from 'components/layouts/PageContentLayout';
import Plyr from 'lib/media/Plyr';
import React from 'react';
import SecretsSlicedWord from 'components/atoms/images/SecretsSlicedWord';
import SlateContent from 'components/molecules/cms/SlateContent';
import StaticPageHeader from 'components/molecules/banners/StaticPageHeader';
import TrackSectionView from 'lib/events/TrackSectionView';
import { graphql } from 'gatsby';
import { makeClassNames } from 'lib/util';
import useAppEventDispatcher from 'lib/events/hooks';
import { useInView } from 'react-intersection-observer';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query OurStoryPage {
    strapiOurStory {
      banner {
        ...CdnImageData
      }
      brandVideo {
        ...ResponsiveVideo
      }
      founders {
        bio
        name
        avatar {
          ...CdnImageData
        }
      }
      introSlate {
        ...SlateContent
      }
      pageMetadata {
        description
        robotsMetaTag
        title
      }
      secretPanels {
        title
        textSlate {
          ...SlateContent
        }
        image {
          ...CdnImageData
        }
        secondaryImage {
          ...CdnImageData
        }
      }
      secretsTextSlate {
        ...SlateContent
      }
      solutionBlocks {
        title
        textSlate {
          ...SlateContent
        }
        image {
          ...CdnImageData
        }
        movingImage {
          ...ResponsiveVideo
        }
        marks {
          url
          name
          image {
            alternativeText
            url
            width
            height
          }
        }
      }
      solutionsTextSlate {
        ...SlateContent
      }
      tagline
    }
  }
`;

type OurStoryProps = AppPageProps<OurStoryPageQuery>;

const OurStory: React.FC<OurStoryProps> = props => {
  const {
    banner,
    brandVideo,
    founders,
    introSlate,
    pageMetadata,
    secretPanels,
    secretsTextSlate,
    solutionBlocks,
    solutionsTextSlate,
    tagline
  } = props.data.strapiOurStory as unknown as StrapiOurStory;
  const pageContext = props.pageContext;
  const eventDispatcher = useAppEventDispatcher();
  const { ref: secretsRef, inView: secretsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  const { ref: secretCarouselRef, inView: secretsCarouselInView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  });
  const { ref: solutionsRef, inView: solutionsInView } = useInView({
    triggerOnce: true,
    threshold: 0.6
  });

  const secretsInViewClassNames = makeClassNames(
    'duration-2000 transform',
    secretsInView ? 'opacity-100' : 'opacity-0'
  );

  const solutionsInViewClassNames = makeClassNames(
    'duration-2000 transform',
    solutionsInView ? 'opacity-100' : 'opacity-0'
  );

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      {/*
       * Intro
       */}
      <StaticPageHeader
        title={pageMetadata.title}
        subtitle={tagline}
        image={banner as CdnImageDataFragment}
      />
      <Container className="pt-rhythm3 xl:hidden">
        <SlateContent content={introSlate} />
      </Container>

      {/*
       * Video
       */}
      <Plyr
        video={brandVideo}
        className="w-full pt-rhythm3 xl:hidden"
        onPlay={() =>
          eventDispatcher.dispatch(AppEventName.StartedWatchingCompanyVideo)
        }
        onEnd={() =>
          eventDispatcher.dispatch(AppEventName.FinishedWatchingCompanyVideo)
        }
      />

      <Container className="hidden items-center py-rhythm6 xl:flex">
        <Plyr
          video={brandVideo}
          className="flex-1"
          onPlay={() =>
            eventDispatcher.dispatch(AppEventName.StartedWatchingCompanyVideo)
          }
          onEnd={() =>
            eventDispatcher.dispatch(AppEventName.FinishedWatchingCompanyVideo)
          }
        />
        <SlateContent className="flex-1 pl-rhythm3" content={introSlate} />
      </Container>

      {/*
       * Problem: Secrets in the beauty industry
       */}
      <div className="grid grid-cols-12 items-end">
        <Container
          as="h2"
          ref={secretsRef}
          className="relative col-span-12 pt-rhythm4 text-primary lg:col-span-5"
        >
          <div className={secretsInViewClassNames}>
            <span className="mr-3 text-f2">The</span>
            <span className="text-f4 font-extrabold">beauty</span>
          </div>
          <span
            className={makeClassNames(
              '-mt-4 block text-f4 font-extrabold',
              secretsInViewClassNames
            )}
          >
            industry
          </span>
          <span
            className={makeClassNames(
              '-mt-2 block text-f2',
              secretsInViewClassNames
            )}
          >
            has many
          </span>
          <SecretsSlicedWord
            withAnimation
            animate={secretsInView}
            className="max-w-full"
          />

          <SlateContent className="pt-rhythm2" content={secretsTextSlate} />
        </Container>

        <div
          className="col-span-12 pb-rhythm3 lg:col-span-7"
          ref={secretCarouselRef}
        >
          <IndustrySecrets
            animate={secretsCarouselInView}
            cards={secretPanels as unknown as IndustrySecretCartData[]}
          />
        </div>
      </div>

      {/*
       * Solutions
       */}
      <Container
        ref={solutionsRef}
        as="h2"
        className="relative flex-col justify-center pt-rhythm3 text-primary lg:pt-rhythm4"
      >
        <div className="flex lg:justify-center">
          <span
            className={makeClassNames(
              'mr-2 self-end pb-6 text-f3 leading-none',
              solutionsInViewClassNames
            )}
          >
            Let's
          </span>
          <span className="inline-block">
            <FixWord animate={solutionsInView} />
          </span>
        </div>
        <div
          className={makeClassNames(
            '-mt-2 flex lg:mt-0 lg:items-end lg:justify-center',
            solutionsInViewClassNames
          )}
        >
          <span className="mr-2 text-f4 leading-none">this</span>
          <span className="text-f5 font-extrabold leading-none">mess</span>
        </div>
        <div className="lg:flex lg:justify-center">
          <span
            className={makeClassNames(
              'block text-f3 font-semibold',
              solutionsInViewClassNames
            )}
          >
            together
          </span>
        </div>
      </Container>
      <Container className="py-rhythm3 lg:flex lg:justify-center">
        <SlateContent content={solutionsTextSlate} />
      </Container>
      {solutionBlocks.map((item, index) => (
        <CompanyPillar
          key={item?.title || index}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          item={item as any as PillarData}
          index={index}
          marks={item?.marks as StrapiMark[]}
          isLast={index === solutionBlocks.length - 1}
        />
      ))}
      <TrackSectionView
        eventFactory={() => ({ name: AppEventName.SawAllCompanyPillars })}
      />

      {/*
       * Founders
       */}
      <div className="flex items-center justify-center bg-primary py-rhythm3">
        <Container className="text-center">
          <Heading withoutSpacing level={2} color="text-secondary">
            <Balancer>Meet the founders</Balancer>
          </Heading>
        </Container>
      </div>
      <Container className="justify-center py-rhythm3 md:flex md:space-x-rhythm5">
        <FoundersInfo data={founders as FounderData[]} />
      </Container>
      <TrackSectionView
        eventFactory={() => ({ name: AppEventName.SawFoundersInfo })}
      />
    </PageContentLayout>
  );
};

export default OurStory;
